import type { DatoLocale } from '@/lib/datocms';
import type { WebsitePageParams } from '@/pages/[region]/[language]/[[...pathParts]]';

import type { Cohort } from '@ironhack/types';
import type { SetRequired } from 'type-fest';

export type Campus =
  | 'rmt'
  | 'ams'
  | 'bcn'
  | 'ber'
  | 'lis'
  | 'lon'
  | 'mad'
  | 'mex'
  | 'mia'
  | 'par'
  | 'sao';

const region = [
  'ww',
  'br',
  'mx',
  'es',
  'fr',
  'pt',
  'nl',
  'de',
  'uk',
  'us',
] as const;
export type Region = (typeof region)[number];
export const isRegion = (x: string) => region.includes(x as Region);

const language = ['br', 'pt', 'de', 'en', 'es', 'fr', 'nl'] as const;
export type Language = (typeof language)[number];
export const isLanguage = (x: string) => language.includes(x as Language);

export type HrefLangTag = { hrefLang: string; href: string };

export type PageProps<TCmsData> = {
  data: TCmsData;
};

export type PageData<
  TCmsData extends Record<string, unknown>,
  TVariables,
  TPageData extends Record<string, unknown>
> = {
  cmsData: TCmsData;
  pageData: TPageData & { gaPageType: string; gaCategory: string };
  modulesOrder: string[];
  query: {
    query: string;
    preview: boolean;
    variables: TVariables;
  };
};

export type PageDataContext = {
  params: WebsitePageParams;
  regionId: string;
  courseId?: string;
  campusId?: string;
  locale: DatoLocale;
  preview: boolean;
  experiment: {
    experimentId?: string;
    variantId?: number;
  };
};

type FullCohort = Required<Cohort>;

export type WebCohort = Pick<
  FullCohort,
  | 'campus'
  | 'end_date'
  | 'format'
  | 'id'
  | 'language'
  | 'start_date'
  | 'timezone'
  | 'track'
> & {
  external_ids: SetRequired<FullCohort['external_ids'], 'salesforce'>;
  price: Required<FullCohort['price']>;
  timetable: Array<null | Required<
    Exclude<FullCohort['timetable'][number], undefined>
  >>;
};

export type WithTracking<T = Record<string, unknown>> = T & {
  tracking?: { f?: string };
};

export type CourseTrack = Exclude<WebCohort['track'], 'other'>;
export type CourseFormat = Exclude<WebCohort['format'], 'other'>;
