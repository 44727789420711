import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { drop, take } from 'ramda';
import { Flex, backgroundToTokens } from '@ironhack/design-system2';

import { PageContextProvider } from '@/contexts';
import { request } from '@/lib/datocms/client';
import { NotFoundPageHeader, notFoundPageHeaderFragment } from '@/modules';
import {
  buttonFragment,
  responsiveImageFragment,
  targetFragment,
} from '@/lib/datocms';
import { Language, Region, isLanguage, isRegion } from '@/types';
import { urlToDatoLocale } from '@/lib/utils';

import type { ReactElement } from 'react';
import type { GetStaticProps } from 'next';
import type { BackgroundColor } from '@ironhack/design-system2';

type AllNotFoundPagesQuery = {
  allNotFoundPages: Array<{
    headerBackground: BackgroundColor;
    region: {
      code: string;
    };
    _allHeaderLocales: Array<{
      locale: string;
      value: [NotFoundPageHeader];
    }>;
  }>;
};

const allNotFoundPagesQuery = `
  query AllNotFoundPagesQuery {
    allNotFoundPages {
      headerBackground
      region {
        code
      }
      _allHeaderLocales {
        locale
        value {
          ...notFoundPageHeaderFragment
        }
      }
    }
  }

  ${notFoundPageHeaderFragment}

  ${buttonFragment}
  ${responsiveImageFragment}
  ${targetFragment}
`;

type PageProps = AllNotFoundPagesQuery;
type PageData = NotFoundPageHeader;

type PathParams = {
  region: Region;
  locale: Language;
};

const getPathParams = (path: string): PathParams => {
  const pathParts = drop(1, path.split('/'));
  let [region, locale] = take(2, pathParts) as [Region, Language];

  if (!isRegion(region)) {
    region = 'es';
  }
  if (!isLanguage(locale)) {
    locale = 'en';
  }

  return {
    region,
    locale,
  };
};

const getPageData = (pathParams: PathParams, data: PageProps): PageData => {
  const { region, locale } = pathParams;

  const notFoundPage = data.allNotFoundPages.find(
    (page) => page.region.code === region
  );

  const pageLocale = notFoundPage._allHeaderLocales.find(
    (header) => header.locale === urlToDatoLocale[`${region}${locale}`]
  );

  if (!pageLocale) {
    return notFoundPage._allHeaderLocales.find(
      (header) => header.locale === urlToDatoLocale[`${region}en`]
    ).value[0];
  }

  return pageLocale.value[0];
};

const NotFoundPage = (props: PageProps): ReactElement => {
  const { allNotFoundPages } = props;
  const router = useRouter();
  const [data, setData] = useState<PageData>({
    title: '',
    description: '',
    image: null,
    buttonText: '',
  });

  useEffect(() => {
    const { asPath: path } = router;
    const pathParams = getPathParams(path);
    const pageData = getPageData(pathParams, props);
    setData(pageData);
  }, [props, router]);

  const { bg } = backgroundToTokens(
    allNotFoundPages[0].headerBackground || 'brand'
  );

  return (
    <PageContextProvider
      pageContext={{
        params: null,
        locale: null,
        cmsData: null,
        pageData: { gaPageType: '404', gaCategory: '', languageOptions: [] },
      }}
    >
      <Flex bg={bg} direction="column" h="100vh" key="not-found">
        <NotFoundPageHeader data={data} my="auto" />
      </Flex>
    </PageContextProvider>
  );
};

export const getStaticProps: GetStaticProps<PageProps> = async () => {
  const { allNotFoundPages } = await request<AllNotFoundPagesQuery>({
    query: allNotFoundPagesQuery,
  });

  return {
    props: {
      allNotFoundPages,
    },
  };
};

export default NotFoundPage;
